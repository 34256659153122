import React, { useState } from 'react';
import { Menu, Icon, Button } from 'antd';
import { useDispatch } from 'react-redux';
import { doLogout } from '../../reducers/auth.reducer';
import { routes } from '../../utils/routers.constants';
import { history } from '../../config/Store.config';
import { permissionKeys } from '../../utils/permissions';
import useCheckPermissions from '../../hooks/useCheckPermissions';

import './SideMenu.scss';

const { Item, ItemGroup, SubMenu } = Menu;

interface ITitleMenu {
    iconName: string,
    title: string,
};

const SideMenu: React.FC = () => {
    const [collapsed, setCollapsed] = useState<boolean>(true);
    const dispatch = useDispatch();
    const checkPermissions = useCheckPermissions();

    const toggleCollapsed = () => {
        setCollapsed(!collapsed);
    };

    function handleLogout(): void {
        dispatch(doLogout());
    }

    function handleGoTo(url: string): void {
        history.push(url);
    }

    return (
        <section className={`side-menu-container ${collapsed ? 'close' : 'open'}`}>
            <Button type='default' onClick={toggleCollapsed} style={{ marginBottom: 16 }}>
                <Icon type={collapsed ? 'menu-unfold' : 'menu-fold'} />
            </Button>

            <Menu
                defaultSelectedKeys={['dashboard']}
                mode='inline'
                inlineCollapsed={collapsed}
            >

                <SubMenu
                    key='dashboard'
                    title={(
                        <TitleMenu
                            iconName='line-chart'
                            title='Dashboard'
                        />
                    )}
                >
                    <ItemGroup>
                        <Item
                            onClick={() => handleGoTo(routes.PANEL_CONTROL)}
                            key='panelcontrol'
                        >
                            <TitleMenu
                                iconName='solution'
                                title='Visão Geral'
                            />
                        </Item>
                        <Item
                            onClick={() => handleGoTo(routes.PANEL_CONTROL_COACHES)}
                            key='panelcontrol_coaches'
                        >
                            <TitleMenu
                                iconName='team'
                                title='Coaches'
                            />
                        </Item>
                        <Item
                            onClick={() => handleGoTo(routes.PANEL_CONTROL_COACHING)}
                            key='panelcontrol_coaching'
                        >
                            <TitleMenu
                                iconName='table'
                                title='Coaching'
                            />
                        </Item>
                        <Item
                            onClick={() => handleGoTo(routes.PANEL_CONTROL_GRAPH)}
                            key='panelcontrol_graph'
                        >
                            <TitleMenu
                                iconName='area-chart'
                                title='Gráfico Geral'
                            />
                        </Item>
                    </ItemGroup>
                </SubMenu>

                {checkPermissions([permissionKeys.SCIS_CLASSES_LIST]) &&
                    <SubMenu
                        key='scisClasses'
                        title={(
                            <TitleMenu
                                iconName='contacts'
                                title='Turmas'
                            />
                        )}

                    >
                        <ItemGroup>
                            <Item
                                onClick={() => handleGoTo(routes.PANEL_CONTROL_CLASSES)}
                                key='panelcontrol_classes'
                            >
                                <TitleMenu
                                    iconName='contacts'
                                    title='Dashboard'
                                />
                            </Item>
                            <Item
                                onClick={() => handleGoTo(routes.SCIS_CLASSES)}
                                key='scisClasses'
                            >
                                <TitleMenu
                                    iconName='solution'
                                    title='Turmas'
                                />
                            </Item>
                        </ItemGroup>
                    </SubMenu>
                }

                <SubMenu
                    key='reports'
                    title={(
                        <TitleMenu
                            iconName='file-text'
                            title='Relatórios' />
                    )}>
                    <ItemGroup>
                        <Item
                            onClick={() => handleGoTo(routes.COACHES_REPORT)}
                            key={'reports-coaches'}>
                            <TitleMenu
                                iconName='user'
                                title='Coaches' />
                        </Item>

                        <Item
                            onClick={() => handleGoTo(routes.COACHING_HOURS_REPORT)}
                            key={'reports-coaching-hours'}>
                            <TitleMenu
                                iconName='clock-circle'
                                title='Horas de coaching' />
                        </Item>

                        <Item
                            onClick={() => handleGoTo(routes.COACHING_CYCLE_REPORT)}
                            key='reports-coaching-cycle'
                        >
                            <TitleMenu
                                iconName='sync'
                                title='Ciclos de coaching'
                            />
                        </Item>

                        <Item
                            onClick={() => handleGoTo(routes.INVOICES_REPORT)}
                            key='invoices-reports'
                        >
                            <TitleMenu
                                iconName='file'
                                title='Notas Fiscais'
                            />
                        </Item>

                        <Item
                            onClick={() => handleGoTo(routes.MONTHLY_COACHING)}
                            key='monthly-coaching'
                        >
                            <TitleMenu
                                iconName='file-text'
                                title='Coaching Mensal'
                            />
                        </Item>

                        <Item
                            onClick={() => handleGoTo(routes.EVALUATION_REPORT)}
                            key={'reports-evaluation'}>
                            <TitleMenu
                                iconName='file-text'
                                title='Avaliações'
                            />
                        </Item>

                        <Item
                            onClick={() => handleGoTo(routes.EXPERIMENTAL_SESSION_REPORT)}
                            key={'reports-experimental-section'}>
                            <TitleMenu
                                iconName='file-text'
                                title='Sessões experimentais'
                            />
                        </Item>
                    </ItemGroup>
                </SubMenu>

                <SubMenu
                    key='users'
                    title={(
                        <TitleMenu
                            iconName='user'
                            title='Usuários'
                        />
                    )}
                >
                    <ItemGroup>
                        <Item
                            onClick={() => handleGoTo(routes.PROFILE_COACHES)}
                            key='profile-coaches'
                            hidden={!checkPermissions([permissionKeys.USER_PROFILE])}
                        >
                            <TitleMenu
                                iconName='profile'
                                title='Perfis'
                            />
                        </Item>
                        <Item
                            onClick={() => handleGoTo(routes.COACHES)}
                            key='coach'
                            hidden={!checkPermissions([permissionKeys.USER_COACHES])}
                        >
                            <TitleMenu
                                iconName='user'
                                title='Coaches'
                            />
                        </Item>
                        <Item
                            onClick={() => handleGoTo(routes.USERS)}
                            key='allUsers'
                            hidden={!checkPermissions([permissionKeys.USER_USERS])}
                        >
                            <TitleMenu
                                iconName='team'
                                title='Todos os usuários'
                            />
                        </Item>
                        <Item
                            onClick={() => handleGoTo(routes.PAYMENTS_LIST)}
                            key='allPayments'
                            hidden={!checkPermissions([permissionKeys.USER_PAYMENT])}
                        >
                            <TitleMenu
                                iconName='money-collect'
                                title='Todos os pagamentos'
                            />
                        </Item>
                        <Item
                            onClick={() => handleGoTo(routes.COACHEES)}
                            key='coachee'
                            hidden={!checkPermissions([permissionKeys.USER_COACHEE])}
                        >
                            <TitleMenu
                                iconName='usergroup-add'
                                title='Coachees'
                            />
                        </Item>
                        <Item
                            key='blocklist'
                            onClick={() => handleGoTo(routes.BLOCKLIST)}
                            hidden={!checkPermissions([permissionKeys.USER_BLOCKLIST])}
                        >
                            <TitleMenu iconName='stop' title='BlockList' />
                        </Item>
                        <Item
                            onClick={() => handleGoTo(routes.COACHES_INTEGRATIONS)}
                            key='importation'
                            hidden={!checkPermissions([permissionKeys.USER_IMPORT])}
                        >
                            <TitleMenu
                                iconName='upload'
                                title='Importação'
                            />
                        </Item>
                        <Item
                            onClick={() => handleGoTo(routes.ADMINISTRATORS)}
                            key='administrators'
                            hidden={!checkPermissions([permissionKeys.USER_ADMIN])}
                        >
                            <TitleMenu
                                iconName='tool'
                                title='Administradores'
                            />
                        </Item>

                        <Item
                            onClick={() => handleGoTo(routes.AUDIT)}
                            key='audit'
                            hidden={!checkPermissions([permissionKeys.USER_AUDIT])}
                        >
                            <TitleMenu
                                iconName='audit'
                                title='Auditoria'
                            />
                        </Item>
                    </ItemGroup>
                </SubMenu>

                <SubMenu
                    key='conteúdo'
                    title={(
                        <TitleMenu
                            iconName='appstore'
                            title='Conteúdo'
                        />
                    )}>
                    <ItemGroup>
                        <Item
                            key='videos'
                            onClick={() => handleGoTo(routes.VIDEOS)}
                        >
                            <TitleMenu
                                iconName='video-camera'
                                title='Vídeos'
                            />
                        </Item>

                        <Item
                            key='categories'
                            onClick={() => handleGoTo(routes.CATEGORIES)}
                        >
                            <TitleMenu
                                iconName='appstore'
                                title='Categorias'
                            />
                        </Item>

                        <Item
                            key='register-units'
                            onClick={() => handleGoTo(routes.REGISTER_UNITS)}
                        >
                            <TitleMenu
                                iconName='file-search'
                                title='Núcleos'
                            />
                        </Item>

                        <Item
                            key='resources'
                            onClick={() => handleGoTo(routes.RESOURCES)}
                        >
                            <TitleMenu
                                iconName='file-text'
                                title='Resources'
                            />
                        </Item>

                        <Item
                            key='urlShorteners'
                            onClick={() => handleGoTo(routes.URL_SHORTENERS)}
                        >
                            <TitleMenu
                                iconName='paper-clip'
                                title='Encurtador de Urls'
                            />
                        </Item>

                        <Item
                            key='notificationsList'
                            onClick={() => handleGoTo(routes.NOTIFICATIONS_LIST)}
                        >
                            <TitleMenu
                                iconName='bell'
                                title='Notificações'
                            />
                        </Item>
                    </ItemGroup>
                </SubMenu>
                <Item
                    key='Fale Conosco'
                    onClick={() => handleGoTo(routes.CONTACT_US)}
                >
                    <TitleMenu
                        iconName='wechat'
                        title='Fale Conosco'
                    />
                </Item>

                <SubMenu
                    key='vendas'
                    title={(
                        <TitleMenu
                            iconName='dollar'
                            title='Vendas'
                        />
                    )}>
                    <ItemGroup>
                        <Item
                            key='cadastros'
                            onClick={() => handleGoTo(routes.SALES_REGISTRATIONS_LIST)}
                        >
                            <TitleMenu
                                iconName='file-text'
                                title='Cadastros'
                            />
                        </Item>
                        <Item
                            key='internalLinks'
                            onClick={() => handleGoTo(routes.INTERNAL_LINKS_LIST)}
                        >
                            <TitleMenu
                                iconName='file-text'
                                title='Moderação de links'
                            />
                        </Item>
                    </ItemGroup>
                </SubMenu>

                {checkPermissions([permissionKeys.FINANCEIRO_LIST]) &&
                    <SubMenu
                        key='financeiro'
                        title={(
                            <TitleMenu
                                iconName='rise'
                                title='Financeiro'
                            />
                        )}>
                        <ItemGroup>
                            <Item
                                key='pagamentos'
                                onClick={() => handleGoTo(routes.LOOSE_PAYMENT)}
                            >
                                <TitleMenu
                                    iconName='file-text'
                                    title='Pagamentos Avulsos'
                                />
                            </Item>
                            <Item
                                key='vendas'
                                onClick={() => handleGoTo(routes.SALES_SF)}
                            >
                                <TitleMenu
                                    iconName='file-text'
                                    title='Vendas SF'
                                />
                            </Item>
                        </ItemGroup>
                    </SubMenu>
                }

                <Item
                    key='logout'
                    onClick={handleLogout}
                >
                    <TitleMenu
                        iconName='logout'
                        title='Sair'
                    />
                </Item>
            </Menu>
        </section>
    );
};

const TitleMenu = ({ iconName, title }: ITitleMenu) => (
    <span>
        <Icon type={iconName} />
        <span>{title}</span>
    </span>
)

export default SideMenu;
