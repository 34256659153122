/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router';
import TitleSection from '../../components/title-section/TitleSection';
import { Icon } from 'antd';
import handleError from '../../utils/handleError';
import { listSales, listAllSales, updateSaleSF } from '../../utils/webApi';
import { routes } from '../../utils/routers.constants';
import { Link } from 'react-router-dom';
import useCheckPermissions from '../../hooks/useCheckPermissions';

import './SalesSF.scss';
import { history } from '../../config/Store.config';
import SalesList from './sales-list/SalesList';
import { IListSales, ISale, ISaleDetail, SalesTypes } from '../../models/sales-sf.models';
import SalesEdit from './sales-edit/SalesEdit';

const SalesSF = ({ match }: RouteComponentProps) => {
    const [total, setTotal] = useState<number>(0);
    const [page, setPage] = useState<number>(1);
    const [pageSize, setPageSize] = useState<number>(10);
    const [sales, setSales] = useState<ISale[]>([]);
    const [isLoading, setLoading] = useState<boolean>(false);
    const [idCoach, setIdCoach] = useState<string|undefined>((match.params as any).id);
    const [selectedSale, setSelectedSale] = useState<ISaleDetail>();
    const checkPermissions = useCheckPermissions();

    const getSales = useCallback(async function(cPage: number, cPageSize: number, cSearch?:string, cFrom?:string, cTo?:string, cTypesFields: SalesTypes[] = [], noLoading: boolean = false){
        try {
            setLoading(noLoading ? false : true);
            const response = (await listAllSales({page: cPage, pageSize: cPageSize, from: cFrom, to: cTo, search: cSearch, types: cTypesFields})
                ) as IListSales;
            setSales(response.data);
            setTotal(idCoach ? sales.length : response.total);
        } catch (error) {
            handleError(error)
        } finally {
            setLoading(false);
        }
    }, [idCoach, page, pageSize]);

    useEffect(() => {
        const params = match.params as {id?: string};
        setIdCoach(params.id);
    }, [match.params])

    useEffect(() => {
        if (!idCoach) {
            getSales(page, pageSize);
        }
    }, [page, pageSize, getSales, idCoach]);

    const handleEditSale = async (sale: ISale) => {
        try {
            const response = await listSales(sale.orderId);

            setSelectedSale(response);
        }
        catch (error) {
            handleError(error);
        }
    };

    function onChangeSaleEdit(key?: string, value?: any, key2?: string, value2?: any) {
        if (!selectedSale) return;
        
        const updatedSale = { ...selectedSale };
    
        if (key) {
            updatedSale.customer = { ...updatedSale.customer, [key]: value };
        }
    
        if (key2) {
            updatedSale.customer = { ...updatedSale.customer, [key2]: value2 };
        }
    
        setSelectedSale(updatedSale as ISaleDetail);
    }

    const onSaveSale = async function(
        orderId: string,
        firstName: string,
        lastName: string,
        email: string,
        cpf: string,
        city: string,
        country: string,
        cep: string,
        phone: string
    ){
        if (selectedSale){
            try {
                const sale = {
                    orderId: orderId,
                    customer: {
                        firstName: firstName,
                        lastName: lastName,
                        email: email,
                        address: {
                            city: city,
                            country: country,
                            postalCode: cep,
                        },
                        document: {
                            number: cpf,
                            type: "CPF"
                        },
                        phone: phone,
                    }
                    
                };
                await updateSaleSF(orderId, sale);
                getSales(page, pageSize);
                setSelectedSale(undefined);
            } catch (error) {
                handleError(error);
            }
        }
    }

    return (
        <section>
            {
                idCoach &&
                    <Link className="link-redirect-coaches" to={routes.COACHES} onClick={(e) => {
                        e.preventDefault();
                        history.goBack();
                    }}>
                        <Icon style={{ marginRight: 10 }} type="arrow-left" />
                        Voltar
                    </Link>
            }

            <TitleSection title="Vendas Sales Force" />
            <SalesList
                isLoading={isLoading}
                sales={sales}
                checkPermissions={checkPermissions}
                page={page}
                pageSize={pageSize}
                idCoach={idCoach}
                total={total}
                onChangePage={setPage}
                onChangePageSize={setPageSize}
                onEditSale={handleEditSale}
            />

            {selectedSale && (
                <SalesEdit
                    onOk={onSaveSale}
                    onChange={onChangeSaleEdit}
                    onClose={() => setSelectedSale(undefined)}
                    sale={selectedSale}
                    loading={isLoading}
                />
            )}
        </section >
    )
};

export default SalesSF;
