import React from 'react';
import { Button, Modal } from 'antd';
import { ISaleDetail } from '../../../models/sales-sf.models';
import Input from '../../../components/Input/Input';
import './SalesEdit.scss';

interface SalesEditProps {
  onChange(name: string, value: any): void;
  onOk(
    orderId: string,
    firstName: string,
    lastName: string,
    email: string,
    city: string,
    country: string,
    cep: string,
    cpf: string,
    phone: string
  ): void;
  onClose: () => void;
  sale: ISaleDetail;
  loading: boolean;
}

const SalesEdit: React.FC<SalesEditProps> = ({
  onChange,
  onOk,
  onClose,
  sale,
  loading,
}) => {

  return (
    <Modal
      className='modal-sales-edit-sf'
      title="Detalhes da Venda"
      visible={true}
      onCancel={onClose}
      footer={null}
      destroyOnClose={true}
      width={600}
    >
      <form className='grid-info-sales-sf'>
        <div>
          <span>Primeiro Nome</span>
          <Input
            placeholder='Primeiro Nome'
            value={sale.customer.firstName}
            onChange={({ target }) => onChange('firstName', target.value)}
          />
        </div>
        <div>
          <span>Último Nome</span>
          <Input
            placeholder='Último Nome'
            value={sale.customer.lastName}
            onChange={({ target }) => onChange('lastName', target.value)}
          />
        </div>
        
        <div>
          <span>Email</span>
          <Input
            placeholder='Email'
            value={sale.customer.email}
            onChange={({ target }) => onChange('email', target.value)}
          />
        </div>
        
        <div>
          <span>CPF</span>
          <Input
            placeholder='CPF'
            value={sale.customer.document.number}
            onChange={({ target }) => onChange('document', { number: target.value })}
          />
        </div>
        
        <div>
          <span>Cidade</span>
          <Input
            placeholder='Cidade'
            value={sale.customer.address.city}
            onChange={({ target }) => onChange('city', target.value)}
          />
        </div>
        
        <div>
          <span>País</span>
          <Input
            placeholder='País'
            value={sale.customer.address.country}
            onChange={({ target }) => onChange('country', target.value)}
          />
        </div>
        
        <div>
          <span>CEP</span>
          <Input
            placeholder='CEP'
            value={sale.customer.address.postalCode}
            onChange={({ target }) => onChange('postalCode', target.value)}
          />
        </div>
        
        <div>
          <span>Telefone</span>
          <Input
            placeholder='Telefone'
            value={sale.customer.phone}
            onChange={({ target }) => onChange('phone', target.value)}
          />
        </div>
      </form>

      <div className='buttons-bottom-sales-sf'>
        <Button
          className="btn-cancel"
          onClick={onClose}
        >
          Cancelar
        </Button>
        <Button
          disabled={loading}
          type="primary"
          onClick={() => onOk(
            sale.orderId,
            sale.customer.firstName,
            sale.customer.lastName,
            sale.customer.email,
            sale.customer.document.number,
            sale.customer.address.city,
            sale.customer.address.country,
            sale.customer.address.postalCode,
            sale.customer.phone
          )}
        >
          {loading ? "Salvando..." : "Salvar"}
        </Button>
      </div>
    </Modal>
  );
};

export default SalesEdit;
