import React from 'react';
import { Table, Icon, Typography, Pagination } from 'antd';

import './SalesList.scss';
import { ISale } from '../../../models/sales-sf.models';

const { Text } = Typography;

interface IProps {
    sales: ISale[],
    isLoading: boolean,
    page: number,
    pageSize: number,
    checkPermissions(permissions: string[]): boolean,
    idCoach?: string,
    total: number,
    onChangePage: (page: number) => void,
    onChangePageSize: (pageSize: number) => void,
    onEditSale: (sale: ISale) => void,
};

const SalesList = ({
    sales,
    isLoading,
    page,
    pageSize,
    idCoach,
    total,
    onChangePage,
    onChangePageSize,
    onEditSale,
 }: IProps) => {

    const columns = [
        {
            title: 'Nome',
            dataIndex: 'nome',
            key: 'nome',
            sorter: (a: ISale, b: ISale) => `${a.firstName} ${a.lastName}`.localeCompare(`${b.firstName} ${b.lastName}`),
            render: (_: any, record: ISale) => `${record.firstName} ${record.lastName}`,
        },
        {
            title: 'Produto',
            dataIndex: 'produto',
            key: 'produto',
            sorter: (a: ISale, b: ISale) => `${a.productCode} ${a.productType}`.localeCompare(`${b.productCode} ${b.productType}`),
            render: (_: any, record: ISale) => {
                const translateProductType = (type: string) => {
                    switch (type) {
                        case 'ANNUAL':
                            return 'Anual';
                        case 'RECURRENT':
                            return 'Recorrente';
                        default:
                            return type;
                    }
                };

                return `${record.productCode} / ${translateProductType(record.productType)}`;
            },
        },
        {
            title: 'ID',
            dataIndex: 'orderId',
            key: 'orderId',
        },
        {
            title: 'Valor',
            dataIndex: 'paymentAmount',
            key: 'paymentAmount',
            sorter: (a: ISale, b: ISale) => a.paymentAmount > b.paymentAmount ? 1 : -1,
            render: (value: number) => {
                const formatToBRL = (amount: number) => {
                    return new Intl.NumberFormat('pt-BR', {
                        style: 'currency',
                        currency: 'BRL',
                    }).format(amount / 100);
                };
        
                return formatToBRL(value);
            },
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            sorter: (a: ISale, b: ISale) => a.status > b.status ? 1 : -1,
        },
        {
            title: 'Ações',
            key: 'action',
            render: (_: any, record: ISale) => (
                <span className="icons-action">
                    {
                        !idCoach && (
                            <Icon
                                type="edit"
                                onClick={() => onEditSale(record)}
                            />
                        )
                    }
                </span>
            )
        },
    ];

    if (!idCoach) {
        columns.splice(1, 0,{
            title: 'E-mail',
            dataIndex: 'email',
            key: 'email',
            sorter: (a: ISale, b: ISale) => a.email > b.email ? 1 : -1,
        });
    }

    return (
        <>
            <Table
                loading={isLoading}
                columns={columns}
                className={"table-sales"}
                dataSource={sales.map((sale: ISale) => ({ ...sale, key: sale.orderId }))}
                pagination={idCoach ? undefined : false}
            />
            {
                !idCoach && (
                    <>
                        <div style={{ textAlign: 'right', marginTop: 10, marginBottom: -15 }}>
                            <Text><b>{total}</b> vendas encontradas</Text>
                        </div>
                        <Pagination
                            className="all-sales-pagination"
                            showSizeChanger
                            onShowSizeChange={(_, pageSize) => onChangePageSize(pageSize)}
                            defaultPageSize={pageSize}
                            defaultCurrent={page}
                            current={page}
                            total={total}
                            pageSizeOptions={['10', '25', '50', '100']}
                            onChange={page => onChangePage(page)}
                        />
                    </>
                )
            }
        </>
    );
};

export default SalesList;
